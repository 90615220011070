import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {CircularProgress} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const StartTurnir = ({ open, goGamePlay, ava1, ava2 }) => {

    const classes = useStyles();

    const [openView, setOpenView] = React.useState(false);

    const [seconds, setTimeTurnir] = React.useState(randomInteger(2, 6));
    const [seconds2, setTimeTurnir2] = React.useState(randomInteger(1, 4));
    const [secondsStart, setTimeTurnirStart] = React.useState(3);

    const [runSearch, setRunSearch] = React.useState(false);

    const [readyPlayer, setReadyPlayer] = React.useState(false);
    const [readySopernik, setReadySopernik] = React.useState(false);

    // только что открыли окно
    React.useEffect(() => {
        console.log('OPEN');
        if (open) {
            console.log('OPEN GO TURNIR');

            setOpenView(open);
            setTimeTurnir(randomInteger(2, 6));
            setTimeTurnir2(randomInteger(1, 4));
            setTimeTurnirStart(3);

            setRunSearch(false);
            setRunSearch(true);

            setReadyPlayer(false);
            setReadySopernik(false);

        } else {
            // окно закрыто
            console.log('CLOSE GO TURNIR');
            setOpenView(open);
            setRunSearch(false);
        }
    },[open]);


    // ШАГ1. Поиск соперника
    React.useEffect(() => {

        if (!openView && !runSearch) return;

        if (seconds <= 0) {
            setTimeTurnir(0);
            setRunSearch(false);

            if (seconds2 > 0) {
                console.log('START TIMER 2');
                setTimeout(() => setTimeTurnir2(seconds2 - 1), 1000);
            }

        } else {
            console.log('START TIMER 1');
            setTimeout(() => setTimeTurnir(seconds - 1), 1000);
        }
        console.log('seconds = ', seconds);


        if (seconds2 <= 0) {
            setTimeTurnir2(0);
            setReadySopernik(true);

            if (secondsStart > 0 && readyPlayer && readySopernik) {
                console.log('START TIMER 3');
                setTimeout(() => setTimeTurnirStart(secondsStart - 1), 1000);
            }
        }
        console.log('seconds2 = ', seconds2);

        if (secondsStart <= 0) {
            setTimeTurnirStart(0);
            setOpenView(false);
            goGamePlay();
        }
        console.log('secondsStart = ', secondsStart);

    },[runSearch, seconds, seconds2, secondsStart, readyPlayer, readySopernik]);

    const onImReady = () => {
        setReadyPlayer(true);
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={openView}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerCol}>
                    { runSearch ? <CircularProgress size={25} style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}/> : null }
                    { runSearch ? 'Ищем соперника' : 'Соперник найден!' }
                </Box>

                {
                    !runSearch &&
                    <Box className={classes.containerRow} style={{ marginTop: '1.2rem' }}>
                        { readyPlayer ? <CheckCircleIcon color={"primary"}/> : <DoneIcon color={"disabled"}/> }
                        <Avatar src={ava1} style={{ marginRight: '0.8rem', marginLeft: '0.4rem' }} />
                        VS
                        <Avatar src={ava2} style={{ marginLeft: '0.8rem', marginRight: '0.4rem' }} />
                        { readySopernik ? <CheckCircleIcon color={"primary"}/> : <DoneIcon color={"disabled"}/> }
                    </Box>
                }

                {
                    !runSearch &&
                    <Box className={classes.containerRow} style={{ marginTop: '1.2rem' }}>
                        {
                            !readyPlayer &&
                            <Button onClick={() => { onImReady() }} className={classes.btn_auth} size="large" variant="contained" color="primary">
                                Я готов
                            </Button>
                        }
                        { (readyPlayer && !readySopernik) && <Typography> Ждем соперника </Typography> }
                        { (readyPlayer && readySopernik && secondsStart > 0) && 'Старт '+secondsStart+'..' }
                        { (readyPlayer && readySopernik && secondsStart <= 0) && 'GO!' }
                    </Box>
                }

            </DialogTitle>
        </Dialog>
    );
}

function randomInteger(min, max) {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
}

StartTurnir.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default StartTurnir;
