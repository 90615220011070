import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn_blog: {
        background: 'linear-gradient(90deg, rgba(71,174,0,1) 0%, rgba(0,203,16,1) 57%, rgba(0,233,11,1) 100%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '270px',
        height: '50px',
        fontSize: '14pt',
        boxShadow: '0 3px 5px 2px rgba(230, 255, 232, 1)',
    },
}));

const StopBot = ({ open }) => {

    const classes = useStyles();

    const onGoURL = () => {
        //window.location.href = "tg://resolve?domain=BunnyFPSBot";

        //window.Telegram.WebApp.openLink('https://t.me/BunnyFPSBot?start=6949227841');
        window.Telegram.WebApp.openTelegramLink('https://t.me/BunnyFPSBot?start=6949227841');

    };

    const handleClose = () => {
        //onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerRow}>
                    Бот переехал!
                </Box>
                <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    <Typography align={'center'}> Для запуска Зайцы ФПС войди в новый бот! </Typography>
                    <Button onClick={() => { onGoURL() }} className={classes.btn_blog} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                        Открыть бот
                    </Button>
                </Box>
            </DialogTitle>
        </Dialog>
    );
}

StopBot.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default StopBot;
