import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TelegramIcon from "@material-ui/icons/Telegram";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ForwardIcon from '@material-ui/icons/Forward';

const useStyles = makeStyles((theme) => ({
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

    },
    btn_tg: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '290px',
        height: '50px',
        fontSize: '11pt',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    },
    btn_complete: {
        background: 'linear-gradient(90deg, rgba(152,223,0,1) 0%, rgba(0,201,21,1) 35%, rgba(59,189,0,1) 100%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '290px',
        height: '50px',
        fontSize: '11pt',
    },
    btn_start: {
        background: 'linear-gradient(9deg, rgba(255,102,0,1) 0%, rgba(255,201,0,1) 100%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '270px',
        height: '50px',
        fontSize: '12pt',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(254, 234, 215, 100)',
    },
}));

const OPList = ({ open, onClickOP, onGoStart, selectPage, have_subs, btn1, btn2, btn3, btn4, btn5, btn6, btn7, btn8, btn9 }) => {

    const classes = useStyles();

    const onGoURL = () => {
        onClickOP(1);
    };
    const onGoURL2 = () => {
        onClickOP(2);
    };
    const onGoURL3 = () => {
        onClickOP(3);
    };
    const onGoURL4 = () => {
        onClickOP(4);
    };
    const onGoURL5 = () => {
        onClickOP(5);
    };
    const onGoURL6 = () => {
        onClickOP(6);
    };
    const onGoURL7 = () => {
        onClickOP(7);
    };
    const onGoURL8 = () => {
        onClickOP(8);
    };
    const onGoURL9 = () => {
        onClickOP(9);
    };


    const handleClose = () => {
        //onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                
                { (selectPage == 1 && have_subs != 1) || (selectPage == 2 && have_subs != 2) || (selectPage == 3 && have_subs != 3) ? 
                    <Box className={classes.containerRow}>
                        <center>Чтобы продолжить измерение мощности подпишись на наших спонсоров 👇</center>
                    </Box>
                : 
                    null
                }

                { (selectPage == 1 && have_subs == 1) || (selectPage == 2 && have_subs == 2) || (selectPage == 3 && have_subs == 3) ? 
                    <Box className={classes.containerRow}>
                        <center>Готово!</center>
                    </Box>
                : 
                    null
                }
                
                { selectPage == 1 ?

                    <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    
                        { btn1 ? 
                            <Button onClick={() => { onGoURL() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.1rem' }}>
                                Спонсор №1
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.1rem' }}>
                                Спонсор №1
                            </Button>
                        }


                        { btn2 ? 
                            <Button onClick={() => { onGoURL2() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №2
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL2() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №2
                            </Button>
                        }


                        { btn3 ? 
                            <Button onClick={() => { onGoURL3() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №3
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL3() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №3
                            </Button>
                        }


                        { have_subs == 1 ?
                            <Box className={classes.containerRow} style={{ marginTop: '0.9rem' }}>
                                <center>Выполнен 1 из 3 шагов</center>
                            </Box>
                        :
                           <Box className={classes.containerRow} style={{ marginTop: '0.9rem' }}>
                                <center>1/3</center>
                            </Box>
                        }


                        { have_subs == 1 ?
                            <Button onClick={() => { onGoStart(1) }} className={classes.btn_tg} endIcon={<ForwardIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Далее
                            </Button>
                        :
                           null
                        }


                    </Box>

                :
                    null
                }
                
                { selectPage == 2 ?

                    <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    
                        { btn4 ? 
                            <Button onClick={() => { onGoURL4() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.1rem' }}>
                                Спонсор №4
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL4() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.1rem' }}>
                                Спонсор №4
                            </Button>
                        }


                        { btn5 ? 
                            <Button onClick={() => { onGoURL5() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №5
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL5() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №5
                            </Button>
                        }


                        { btn6 ? 
                            <Button onClick={() => { onGoURL6() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №6
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL6() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №6
                            </Button>
                        }


                       { have_subs == 2 ?
                            <Box className={classes.containerRow} style={{ marginTop: '0.9rem' }}>
                                <center>Выполнено 2 из 3 шагов</center>
                            </Box>
                        :
                           <Box className={classes.containerRow} style={{ marginTop: '0.9rem' }}>
                                <center>2/3</center>
                            </Box>
                        }


                        { have_subs == 2 ?
                            <Button onClick={() => { onGoStart(2) }} className={classes.btn_tg} endIcon={<ForwardIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Далее
                            </Button>
                        :
                           null
                        }


                    </Box>

                :
                    null
                }


                { selectPage == 3 ?

                    <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    
                        { btn7 ? 
                            <Button onClick={() => { onGoURL7() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.1rem' }}>
                                Спонсор №7
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL7() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.1rem' }}>
                                Спонсор №7
                            </Button>
                        }


                        { btn8 ? 
                            <Button onClick={() => { onGoURL8() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №8
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL8() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №8
                            </Button>
                        }


                        { btn9 ? 
                            <Button onClick={() => { onGoURL9() }} className={classes.btn_complete} endIcon={<CheckCircleIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №9
                            </Button>
                        : 
                            <Button onClick={() => { onGoURL9() }} className={classes.btn_tg} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Спонсор №9
                            </Button>
                        }


                        { have_subs == 3 ?
                            <Box className={classes.containerRow} style={{ marginTop: '0.9rem' }}>
                                <center>Выполнено 3 из 3 шагов</center>
                            </Box>
                        :
                           <Box className={classes.containerRow} style={{ marginTop: '0.9rem' }}>
                                <center>3/3</center>
                            </Box>
                        }

                        { have_subs == 3 ?
                            <Button onClick={() => { onGoStart(3) }} className={classes.btn_start} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                                Продолжить
                            </Button>
                        :
                           null
                        }
                        


                    </Box>

                :
                    null
                }

            </DialogTitle>
        </Dialog>
    );
}

OPList.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default OPList;
