import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn_blog: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        width: '270px',
        height: '50px',
        fontSize: '12pt',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    },
}));

const Bank = ({ open, dollars, onClose }) => {

    const classes = useStyles();

    const onGoURL = () => {
        window.location.href = "tg://resolve?domain=BunnyFPS_Admin";
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerRow}>
                    Вывод денег
                </Box>

                { dollars < 10 &&
                <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    <Typography align={'center'}> Вывести можно на банковскую карту или электронный кошелек. </Typography>
                    <Typography align={'center'} style={{ marginTop: '1.2rem' }}> Для вывода денег на балансе должно быть минимум 10$! </Typography>
                    <Button onClick={() => { handleClose() }} className={classes.btn_blog} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                        ОК
                    </Button>
                </Box>
                }

                { dollars >= 10 &&
                <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    <Typography align={'center'}> Вывести можно на банковскую карту или электронный кошелек. </Typography>
                    <Typography align={'center'} style={{ marginTop: '1.2rem' }}> Доллары переводятся по курсу в валюту вашей страны. </Typography>
                    <Typography align={'center'} style={{ marginTop: '1.2rem' }}> Для вывода средств напишите админу! </Typography>
                    <Button onClick={() => { onGoURL() }} className={classes.btn_blog} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                        Подать заявку
                    </Button>
                </Box>
                }

            </DialogTitle>
        </Dialog>
    );
}

Bank.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default Bank;
