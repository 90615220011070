import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PauseIcon from '@material-ui/icons/Pause';
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: theme.spacing(0),
        color: '#111111',
        fontSize: '13pt',
        fontWeight: 500,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ResultTurnir = ({ open, onClose, victory, stavka, score1, score2, ava1, ava2 }) => {

    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerRow}>
                    { victory ? 'ПОБЕДА!' : 'Проигрыш...' }
                </Box>

                {
                    victory &&
                    <Box className={classes.containerRow} style={{ marginTop: '0.5rem' }}>
                        <Typography variant={'h4'}> +{stavka} </Typography>
                        <Avatar src="https://fps-bot.ru/bunny_fps/menu/imgs/carrot120.png" style={{ width: '60px', height: '60px' }}/>
                    </Box>
                }
                {
                    victory &&
                    <Box className={classes.containerRow} style={{ marginTop: '0.3rem', marginBottom: '1.2rem' }}>
                        <Typography variant={'h6'}> +0.001 </Typography>
                        <Avatar src="https://fps-bot.ru/bunny_fps/menu/imgs/dollar.png" style={{ width: '34px', height: '34px' }}/>

                        <Typography variant={'h6'} style={{ marginLeft: '0.5rem' }}> -1 </Typography>
                        <Avatar src="https://fps-bot.ru/bunny_fps/menu/imgs/energy3.png" style={{ width: '34px', height: '34px' }}/>
                    </Box>
                }
                {
                    !victory &&
                    <Box className={classes.containerRow} style={{ marginTop: '1.1rem', marginBottom: '1.1rem' }}>
                        <Typography variant={'h6'} style={{ marginLeft: '0.5rem' }}> -1 </Typography>
                        <Avatar src="https://fps-bot.ru/bunny_fps/menu/imgs/energy3.png" style={{ width: '34px', height: '34px' }}/>
                    </Box>
                }


                <Box className={classes.containerRow} style={{ marginTop: '0.7rem' }}>
                    <Avatar src={ava1} style={{ marginRight: '0.8rem' }}/>
                    <Typography className={classes.title}> {score1}К </Typography>
                    <Avatar src="https://fps-bot.ru/bunny_fps/menu/imgs/bunny3.png" />
                </Box>
                <Box className={classes.containerRow} style={{ marginTop: '0.7rem' }}>
                    <Avatar src={ava2} style={{ marginRight: '0.8rem' }}/>
                    <Typography className={classes.title}> {score2}К </Typography>
                    <Avatar src="https://fps-bot.ru/bunny_fps/menu/imgs/bunny3.png" />
                </Box>


                <Box className={classes.containerCol} style={{ marginTop: '0.4rem' }}>
                    <Button onClick={() => { handleClose() }} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                        Продолжить
                    </Button>
                </Box>
            </DialogTitle>
        </Dialog>
    );
}

ResultTurnir.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default ResultTurnir;
