import $ from "jquery";

export const saveMyRecord = (score, chat_id, authTG) => {
    if (!authTG) return;

    $.ajax({
        url: 'https://fps-bot.ru/bunny_fps/game/php/setRecord.php',
        type: 'post',
        data: {
            score: score,
            chat_id: chat_id
        },
        success: function (response) {
            console.log(response);
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log(textStatus, errorThrown);
        }
    });
}

export const getFlashVars = () => {
    const parts = document.location.search.substr(1).split("&");
    let flashVars={}, curr;
    for (let i=0; i<parts.length; i++) {
        curr = parts[i].split('=');
        flashVars[curr[0]] = curr[1];
    }
    return flashVars;
}

export const randomInteger = (min, max) => {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
}

