import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import {Slider} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import TheatersIcon from '@material-ui/icons/Theaters';
import EnergyIcon from '@material-ui/icons/OfflineBolt';
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn_blog: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '265px',
        height: '50px',
        fontSize: '12pt',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    },
    btn_video: {
        background: 'linear-gradient(9deg, rgba(255,102,0,1) 0%, rgba(255,201,0,1) 100%)',
        borderRadius: 5,
        border: 0,
        color: 'white',
        width: '190px',
        height: '45px',
        fontSize: '12pt',
        padding: '0 15px',
        boxShadow: '0 3px 5px 2px rgba(254, 234, 215, 100)',
    },
}));

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';
const IOSSlider = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '20px 0',
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -14,
        marginLeft: -14,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 3,
    },
    rail: {
        height: 3,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 9,
        width: 1.5,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

const Stavka = ({ open, onClose, onSearch, codeComplete, onBonus, onBonus30, videoBonusCount, myCarrots, hash, chat_id }) => {

    if (myCarrots === 0) myCarrots = 1; //добавляем всегда 1 морковку если их нет

    const classes = useStyles();

    const [value, setValue] = React.useState(1); // значение ставки от 1 до 5
    const [info, setInfo] = React.useState('За победу +1 морковка'); // инфо в окне
    const [infoColor, setInfoColor] = React.useState('primary'); // цвет инфо в окне

    const [noVideos, setNoVideos] = React.useState(false); // надпись - нет доступных видео
    const [btnBonusVideo, setBtnBonusVideo] = React.useState(false); // кнопка - бонус за просмотр видео
    const [infoVideo, setInfoVideo] = React.useState('Нет доступных видео'); // инфо в окне

    React.useEffect(
        () => {
            if (myCarrots < 1) setValue(1); // ставка 1, если морковок нет
            if (myCarrots >= 1) setValue(1); // ставка по умолчанию 1
        },
        [myCarrots]
    );

    React.useEffect(
        () => {
            if (open) {
                /*
                if (videoBonusCount < 10) setBtnBonusVideo(true);
                else setBtnBonusVideo(false);
                setNoVideos(false);
                */
            }
        },
        [open]
    );

    const handleSliderChange = (event, newValue) => {

        setInfoColor('primary');

        if (newValue === 1) {
            setInfo('За победу +1 морковка');
            setValue(newValue);
        } else {
            if (newValue > myCarrots) {
                setValue(myCarrots);
                setInfo('Нужно от ' + newValue.toString() + ' морковок');
                setInfoColor('error');
            } else {
                setValue(newValue);
                setInfo('Победа +' + newValue + ', проигрыш -' + newValue);
            }
        }

    };

    const handleSearch = (value) => {
        onSearch(value); // переходим к поиску
        reset();
    };

    // сбрасываем по умолчанию
    const reset = () => {
        setValue(1); // значение ставки от 1 до 5
        setInfo('За победу +1 морковка'); // инфо в окне
        setInfoColor('primary'); // цвет инфо в окне
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    // открыть видеорекламу
    const addVideoAds = () => {

        setBtnBonusVideo(false); // прячем кнопку
        setVideoBonus(hash, chat_id); // записываем просмотр и энергию в БД

    };

    function setVideoBonus(hash, chat_id) {
        if (hash === undefined || hash === '') return;

        //setCheckingCode(true); // запущена проверка кода (ждем ответ)

        $.ajax({
            url: 'https://fps-bot.ru/bunny_fps/game/php/setCodeEnergy.php',
            type: 'post',
            data: {
                hash: hash,
                chat_id: chat_id,
                code: 'video_ads'
            },
            success: function (response) {

                console.log('response = ', response);

                // Видеореклама активирована
                if (response === 'video_activated') {
                    console.log('+1 энергия за просмотр');
                    onBonus();
                }

                // Лимит исчерпан
                if (response === 'video_limited') {
                    console.log('Лимит исчерпан');
                    setInfoVideo('Сегодня лимит исчерпан');
                    setNoVideos(true);
                }

            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
            }
        });
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerRow}>
                    Ставка на турнир
                </Box>

                <Box className={classes.containerRow} style={{ marginTop: '0.5rem' }}>
                    <Typography variant={'h4'}> {value} </Typography>
                    <Avatar src="https://fps-bot.ru/bunny_fps/menu/imgs/carrot120.png" style={{ width: '60px', height: '60px' }}/>
                </Box>

                <Box>
                    <IOSSlider
                        defaultValue={1}
                        aria-labelledby="discrete-slider-always"
                        onChange={handleSliderChange}
                        valueLabelDisplay="off"
                        step={1}
                        marks
                        min={1}
                        max={5}
                    />
                </Box>

                <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    <Typography align={'center'} color={infoColor}> {info}  </Typography>
                    <Button onClick={() => { handleSearch(value) }} disabled={infoColor === 'error'} className={classes.btn_blog} startIcon={<SearchIcon/>} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                        Искать соперника
                    </Button>
                </Box>

                { btnBonusVideo &&
                <Box className={classes.containerCol} style={{ marginTop: '0.1rem' }}>
                    <Button onClick={() => { addVideoAds() }} className={classes.btn_video} startIcon={<TheatersIcon/>} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                        +1 энергия
                    </Button>
                </Box>
                }

                { noVideos && <Typography align={'center'} color={'error'} style={{ marginTop: '0.7rem' }}> {infoVideo} </Typography> }

            </DialogTitle>
        </Dialog>
    );
}

Stavka.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default Stavka;
