import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const Iframe = ({ show, source, completeLoad, height, width }) => {

    const src = source;
    const [loadComplete, setLoadComplete] = useState(true);

    useEffect(() => {
        if (show) setLoadComplete(true);
    }, [show]);

    if (!source) return <div>Loading...</div>;

    const onLoadComplete = () => {
        completeLoad();
        setLoadComplete(false);
    };

    return (
        <div className="col-md-12">
            { loadComplete ?
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="80vh"
                >
                    <CircularProgress />
                </Box>
                : null}
            <div className="emdeb-responsive">
                <iframe src={show ? src : ''} onLoad={onLoadComplete} width={width} height={height} style={{display: show ? 'block' : 'none' }} frameBorder="0"></iframe>
            </div>
        </div>
    );
}

export default Iframe;
