import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {CircularProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Preloader = ({ open }) => {

    const classes = useStyles();

    const [openView, setOpenView] = React.useState(false);

    React.useEffect(() => {
        setOpenView(open);
    },[open]);

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={openView}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerCol}>
                    <CircularProgress size={35} style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}/>
                </Box>
            </DialogTitle>
        </Dialog>
    );
}

Preloader.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default Preloader;
