import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TelegramIcon from "@material-ui/icons/Telegram";

const useStyles = makeStyles((theme) => ({
    containerCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn_blog: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        width: '270px',
        height: '50px',
        fontSize: '12pt',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    },
}));

const StopTurnir = ({ open, onClose }) => {

    const classes = useStyles();

    const onGoURL = () => {
        //window.location.href = "tg://resolve?domain=BunnyFPS";
        window.location.href = "https://t.me/+Mtxc06vRCIcwMTli";
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                <Box className={classes.containerRow}>
                    Турнир был завершен
                </Box>
                <Box className={classes.containerCol} style={{ marginTop: '0.8rem' }}>
                    <Typography align={'center'}> Следующий запуск уже скоро, подробности читай в нашем офциальном блоге! </Typography>
                    <Button onClick={() => { onGoURL() }} className={classes.btn_blog} startIcon={<TelegramIcon />} size="large" variant="contained" color="primary" style={{ marginTop: '0.9rem' }}>
                        Перейти в блог
                    </Button>
                </Box>
            </DialogTitle>
        </Dialog>
    );
}

StopTurnir.propTypes = {
    open: PropTypes.bool.isRequired,
};

export default StopTurnir;
